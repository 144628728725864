import React from "react";
import "./style.css";
import PriceSizeDiv from "../PriceSizeDiv";

export default function SelectionPrices(props) {
  const {
    backFirst = {},
    backSecond = {},
    backThird = {},
    layFirst = {},
    laySecond = {},
    layThird = {},
  } = props;
  return (
    <div className="selection-bets-div">
      <div>
        <PriceSizeDiv {...backThird} primary={false} />
        <PriceSizeDiv {...backSecond} primary={false} />
        <PriceSizeDiv {...backFirst} />
      </div>
      <div>
        <PriceSizeDiv side="lay" {...layFirst} />
        <PriceSizeDiv side="lay" {...laySecond} primary={false} />
        <PriceSizeDiv side="lay" {...layThird} primary={false} />
      </div>
    </div>
  );
}
