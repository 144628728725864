import GlobalConfig from "./GlobalConfig";

let baseEndpointUrl = GlobalConfig["back-end_address"];

export const apiGet = async (url, customOptions = {}) => {
  let result = await apiF(url, "", "GET", customOptions).catch((err) => {
    throw err;
  });
  return result;
};

export const apiPost = async (url, body, customOptions = {}) => {
  let result = await apiF(url, body, "POST", customOptions);
  return result;
};

export const apiPut = async (url, body, customOptions = {}) => {
  let result = await apiF(url, body, "PUT", customOptions);
  return result;
};

export const apiDelete = async (url, body, customOptions = {}) => {
  let result = await apiF(url, body, "DELETE", customOptions);
  return result;
};

const apiF = async (url, body, method, customOptions = {}) => {
  let headers = {
    "Content-Type": "application/json",
  };

  let token = localStorage.getItem("token");

  if (token !== null) {
    headers["Authorization"] = "Bearer " + token;
  }

  let options = {
    method: method,
    headers: headers,
    ...customOptions,
  };
  if (method !== "GET") {
    options["body"] = JSON.stringify(body);
  }

  let result = await fetch(baseEndpointUrl + url, options);

  if (result.status === 200) {
    result = await result.json().catch((err) => {
      if (method === "GET") {
        return [];
      } else {
        return {};
      }
    });
    return result;
  } else {
    if (result.status === 403) {
      console.log("reload");
      document.location.reload();
    }
    throw new Error(result.status);
  }
};
