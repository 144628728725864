import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  withStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import SelectionPrices from "../SelectionPrices";
import getAvailableSizeString from "../../utils/getAvailableSizeString";
import MarketCurrentOrder from "./MarketCurrentOrder";
import RunnerHeader from "../RunnerHeader";
import getMoneyOperation from "../../utils/getMoneyOperation";
import FastForwardIcon from "@material-ui/icons/FastForward";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import getFailSuccessCssColor from "../../utils/getFailSuccessCssColor";
import getProfitStatus from "../../utils/getProfitStatus";
import getRunnerProfit from "../../utils/getRunnerProfit";
import ClosePosition from "../ClosePosition";
import getEventDate from "../../utils/getEventDate";

const CustomAccordion = withStyles({
  root: {
    margin: "9px",
    "&$expanded, &$expanded:first-child": {
      margin: "9px",
    },
  },
  expanded: {},
})(Accordion);

const MyDivider = withStyles({
  root: {
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
  },
})(Divider);

const MarketOrder = ({ marketOrder }) => {
  const getSelectionPrices = (toBack, toLay) => {
    let result = {
      backFirst: getSelectionPriceObject(toBack[0]),
      backSecond: getSelectionPriceObject(toBack[1]),
      backThird: getSelectionPriceObject(toBack[2]),
      layFirst: getSelectionPriceObject(toLay[0]),
      laySecond: getSelectionPriceObject(toLay[1]),
      layThird: getSelectionPriceObject(toLay[2]),
    };
    return result;
  };

  const getSelectionPriceObject = (available) => {
    if (!available) {
      return {};
    }

    return {
      price: available.price,
      size: getAvailableSizeString(available.size),
    };
  };

  const printOrder = (order, selectionId) => {
    return (
      <MarketCurrentOrder
        key={order.betId}
        size={getMoneyOperation(order.size, order.sizeCancelled, "-")}
        side={order.side}
        matched={order.sizeMatched}
        price={order.price}
        marketOrder={marketOrder}
        betId={order.betId}
        selectionId={selectionId}
      />
    );
  };

  const printOrders = (orders, selectionId) => {
    if (!orders) {
      return <></>;
    }
    return (
      <div>
        <h5>Orders</h5>
        {orders
          .sort(
            (order1, order2) =>
              new Date(order2.placedDate) - new Date(order1.placedDate),
          )
          .map((order) => printOrder(order, selectionId))}
      </div>
    );
  };

  const getProfits = (next = false) => {
    return marketOrder.marketProfitAndLoss.profitAndLosses.map((profit) => {
      let element = {
        selectionId: profit.selectionId,
        quantity: profit.ifWin,
      };
      if (next) {
        element.quantity = getRunnerProfit(
          marketOrder,
          profit.selectionId,
          true,
        );
      }
      return element;
    });
  };

  const currentProfits = getProfits();
  const nextProfits = getProfits(true);

  const [currentProfitStatus] = getProfitStatus(currentProfits);
  const [nextProfitStatus, nextSelectionId] = getProfitStatus(nextProfits);

  const getStatusColor = (next = false) => {
    const profitStatus = next ? nextProfitStatus : currentProfitStatus;
    return getColorFromStatus(profitStatus);
  };

  const getColorFromStatus = (status) => {
    if (status === "none") {
      return "#fff";
    }
    if (status === "positive") {
      return getFailSuccessCssColor();
    }

    if (status === "negative") {
      return getFailSuccessCssColor("fail");
    }

    if (status === "back") {
      return "#0000ffb8";
    }

    return "#e4738d";
  };

  const getOpposite = (status) => {
    return status === "lay" ? "back" : "lay";
  };

  return (
    <div>
      <CustomAccordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "7fr 1fr 1fr",
              width: "100%",
            }}
          >
            <div>{marketOrder.marketCatalogue.event.name}</div>
            <div>
              <PlayArrowIcon style={{ color: getStatusColor() }} />
            </div>
            <div>
              <FastForwardIcon style={{ color: getStatusColor(true) }} />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          <div>{marketOrder.marketCatalogue.competition.name}</div>
          <div>
            {marketOrder.marketCatalogue.marketName}:{" "}
            {Math.round(marketOrder.marketCatalogue.totalMatched)}€
          </div>
          <div>{getEventDate(marketOrder.marketCatalogue)}</div>
          <MyDivider />
          {/* <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', marginBottom: '1.5rem' }}>
                        <div>{marketOrder.marketCatalogue.competition.name} - {marketOrder.marketCatalogue.marketName}</div>
                        <div>{getEventDate(marketOrder.marketCatalogue)}</div>
                    </div> */}
          {(currentProfitStatus === "back" ||
            currentProfitStatus === "lay") && (
            <>
              <ClosePosition
                toggleButtonText="Close actual position"
                marketOrder={marketOrder}
                nextProfitStatus={getOpposite(currentProfitStatus)}
                nextSelectionId={nextSelectionId}
                allOrders={false}
              />
              <MyDivider />
            </>
          )}
          {(nextProfitStatus === "back" || nextProfitStatus === "lay") && (
            <>
              <ClosePosition
                toggleButtonText="Close orders position"
                marketOrder={marketOrder}
                nextProfitStatus={getOpposite(nextProfitStatus)}
                nextSelectionId={nextSelectionId}
              />
              <MyDivider />
            </>
          )}
          {marketOrder.marketBook.runners.map((runner, index) => (
            <div key={runner.selectionId + "_accordion_details"}>
              <div style={{ width: "343px" }}>
                <RunnerHeader
                  runnerId={runner.selectionId}
                  marketOrder={marketOrder}
                />
              </div>
              <div style={{ width: "343px" }}>
                <SelectionPrices
                  {...getSelectionPrices(
                    runner.ex.availableToBack,
                    runner.ex.availableToLay,
                  )}
                />
              </div>
              {printOrders(runner.orders, runner.selectionId)}
              {index !== marketOrder.marketBook.runners.length - 1 && (
                <MyDivider />
              )}
            </div>
          ))}
        </AccordionDetails>
      </CustomAccordion>
    </div>
  );
};

export default MarketOrder;
