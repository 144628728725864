import getMoneyOperation from "./getMoneyOperation";
import getRunnerProfit from "./getRunnerProfit";

const getPotSize = (marketOrder, allOrders = false) => {
  let profitMap = marketOrder.marketProfitAndLoss.profitAndLosses.map(
    (runner) => runner.ifWin,
  );
  if (allOrders) {
    profitMap = marketOrder.marketBook.runners.map((runner) =>
      getRunnerProfit(marketOrder, runner.selectionId, true),
    );
  }

  const negative = Math.abs(
    Math.max.apply(
      null,
      profitMap.filter((runner) => runner < 0),
    ),
  );
  const positive = Math.min.apply(
    null,
    profitMap.filter((runner) => runner > 0),
  );

  return getMoneyOperation(negative, positive);
};

export default getPotSize;
