import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      //light: '#757ce8',
      main: "#f48fb1",
      //dark: '#002884',
      //contrastText: '#fff',
    },
    secondary: {
      //light: '#757ce8',
      main: "#05fd92",
      //dark: '#002884',
      //contrastText: '#fff',
    },
  },
});

export default theme;
