import React from "react";
import styled from "styled-components";
import getSideCssColor from "../../utils/getSideCssColor";
import getDefaultBackground from "../../utils/getDefaultBackground";

const StyledCicularProgress = styled.div`
  &,
  &:before,
  &:after {
    width: ${(props) => props.radius}rem;
    height: ${(props) => props.radius}rem;
    border-radius: 50%;
  }

  & {
    position: relative;
    transform: rotate(${(props) => props.rotation}deg);
    background: linear-gradient(
      0deg,
      ${(props) => props.sideColor} 50%,
      ${(props) => props.sideColorGrad} 50%
    );
  }

  &:before,
  &:after {
    position: absolute;
  }

  &:before {
    color: ${(props) => props.sideColor};
    content: "${(props) => props.matched}";
    top: 0;
    left: 0;
    background: ${(props) => props.backgroundColor} content-box;
    padding: ${(props) => props.padding}rem;
    box-sizing: border-box;
    font-size: ${(props) => props.fontSize}rem;
    text-align: center;
    line-height: ${(props) => props.radius * 0.75}rem;
    text-transform: uppercase;
    transform: rotate(-${(props) => props.rotation}deg);
    z-index: 1;
  }

  &:after {
    content: "";
    background: linear-gradient(
      transparent 50%,
      ${(props) => props.backgroundColor} 50%
    );
    transform: scale(1.1) rotate(-${(props) => props.rotation}deg);
  }
`;

export default function CircularProgress(props) {
  const {
    side = "back",
    size = 0.0,
    matched = 0.0,
    backgroundColor = getDefaultBackground(),
    radius = 6,
  } = props;
  const fontSize = radius / 4.5;
  const padding = fontSize / 4;
  const sideColor = getSideCssColor(side);
  const sideColorGrad = getSideCssColor(side, false);
  const rotation = size === 0 ? 0 : (180 * matched) / size;

  return (
    <StyledCicularProgress
      radius={radius}
      rotation={rotation}
      sideColor={sideColor}
      sideColorGrad={sideColorGrad}
      matched={matched}
      backgroundColor={backgroundColor}
      padding={padding}
      fontSize={fontSize}
    />
  );
}
