import pips from "./getPips";

export default function getNextPip(price, direction = "up") {
  let increment = getIncrement(price, direction);

  return getPip(price, increment, direction);
}

const getPip = (price, increment, direction) => {
  let pip = price * 100;
  if (direction === "up") {
    pip += increment;
  } else {
    pip -= increment;
  }
  pip = Math.round(pip);
  return pip / 100;
};

const getIncrement = (price, direction) => {
  let prevKey = 1.01;
  for (var key in pips) {
    if (inRange(price, prevKey, key, direction)) {
      return pips[key];
    }
    prevKey = key;
  }
  return 0.01;
};

const inRange = (price, min, max, direction) => {
  if (direction === "down") {
    return price > min && price <= max;
  }
  return price >= min && price < max;
};
