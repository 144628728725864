import React from "react";
import useIntervalApiGet from "../../hooks/useIntervalApiGet";
import MarketOrder from "./MarketOrder";

const MarketOrders = () => {
  const [currentMarketOrders] = useIntervalApiGet("marketOrders");

  const sortByDate = (m1, m2) => {
    const d1 = new Date(m1.marketCatalogue.description.marketTime);
    const d2 = new Date(m2.marketCatalogue.description.marketTime);
    return d1 - d2;
  };

  return (
    <>
      {currentMarketOrders.sort(sortByDate).map((marketOrder) => (
        <MarketOrder
          key={marketOrder.marketBook.marketId}
          marketOrder={marketOrder}
        />
      ))}
    </>
  );
};

export default MarketOrders;
