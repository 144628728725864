export default function getEventDate(marketCatalogue) {
  var oDate = new Date(marketCatalogue.description.marketTime);
  var months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];
  var weekDays = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];
  // var year = oDate.getFullYear();
  var weekDay = weekDays[oDate.getDay()];
  var month = months[oDate.getMonth()];
  var date = oDate.getDate().toString().padStart(2, "0");
  var hour = oDate.getHours().toString().padStart(2, "0");
  var min = oDate.getMinutes().toString().padStart(2, "0");
  // var sec = oDate.getSeconds().toString().padStart(2, "0");

  var time = weekDay + " " + date + " de " + month + ", " + hour + ":" + min;
  return time;
}
