import React, { useState } from "react";
import "./style.css";
import getNextPip from "../../utils/getNextPip";
import getRoundPip from "../../utils/getRoundPip";
import getSideCssColor from "../../utils/getSideCssColor";

export default function PriceSelector({ price = 2, side = "back", onChange }) {
  const [val, setVal] = useState(price);

  const handleMinus = () => {
    setVal((prev) => {
      const newValue = getNextPip(prev, "down");
      onChange(newValue);
      return newValue;
    });
  };

  const handlePlus = () => {
    setVal((prev) => {
      const newValue = getNextPip(prev);
      onChange(newValue);
      return newValue;
    });
  };

  const handleChange = (e) => {
    onChange(getRoundPip(e.target.value));
    setVal(e.target.value);
  };

  const round = () => {
    onChange(getRoundPip(val));
    setVal(getRoundPip(val));
  };

  return (
    <div className="number-input">
      <button
        style={{ backgroundColor: getSideCssColor(side) }}
        onClick={handleMinus}
        className="minus"
      >
        -
      </button>
      <input
        style={{
          border: "solid " + getSideCssColor(side),
          backgroundColor: getSideCssColor(side, false),
        }}
        className="quantity"
        min="0"
        name="quantity"
        value={val}
        type="number"
        onChange={handleChange}
        onBlur={round}
      />
      <button
        style={{ backgroundColor: getSideCssColor(side) }}
        onClick={handlePlus}
        className="plus"
      >
        +
      </button>
    </div>
  );
}
