import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import getAvailableSizeString from "../../utils/getAvailableSizeString";
import getEventDate from "../../utils/getEventDate";
import SelectionPrices from "../SelectionPrices";

const CustomAccordion = withStyles({
  root: {
    margin: "9px",
    "&$expanded, &$expanded:first-child": {
      margin: "9px",
    },
  },
  expanded: {},
})(Accordion);

const MyDivider = withStyles({
  root: {
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
  },
})(Divider);

const TradedEventInfo = ({ event }) => {
  const getSelectionPrices = (toBack, toLay) => {
    let result = {
      backFirst: getSelectionPriceObject(toBack[0]),
      backSecond: getSelectionPriceObject(toBack[1]),
      backThird: getSelectionPriceObject(toBack[2]),
      layFirst: getSelectionPriceObject(toLay[0]),
      laySecond: getSelectionPriceObject(toLay[1]),
      layThird: getSelectionPriceObject(toLay[2]),
    };
    return result;
  };

  const getSelectionPriceObject = (available) => {
    if (!available) {
      return {};
    }
    return {
      price: available.price,
      size: getAvailableSizeString(available.size),
    };
  };

  return (
    <CustomAccordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "7fr 1fr 1fr",
            width: "100%",
          }}
        >
          <div>{event.marketCatalogue.event.name}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>
        <div>
          {event.marketCatalogue.description.marketType}:{" "}
          {Math.round(event.marketCatalogue.totalMatched)}€
        </div>
        <div>{getEventDate(event.marketCatalogue)}</div>
        <MyDivider />
        <div>
          {event.marketBookList &&
            event.marketBookList.length > 0 &&
            event.marketBookList[0].runners.map((runner, index) => (
              <div key={runner.selectionId + "_accordion_details"}>
                <div style={{ width: "343px" }}>
                  {event.runnerCatalogsMap[runner.selectionId].runnerName}
                </div>
                <div style={{ width: "343px" }}>
                  <SelectionPrices
                    {...getSelectionPrices(
                      runner.ex.availableToBack,
                      runner.ex.availableToLay,
                    )}
                  />
                </div>
                <MyDivider />
              </div>
            ))}
        </div>
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default TradedEventInfo;
