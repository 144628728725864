import { useContext, useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { apiPost } from "../Api_Functions";
import AuthenticationContext from "../context/AuthenticationContext";

const useAuth = () => {
  const { isAuthenticated, setAuthenticated } = useContext(
    AuthenticationContext,
  );
  const history = useHistory();
  const jwt = require("jsonwebtoken");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const { exp } = jwt.decode(token);
      if (Date.now() >= exp * 1000) {
        localStorage.removeItem("token");
        setAuthenticated(false);
        return;
      }
      setAuthenticated(true);
      return;
    }
    setAuthenticated(false);
  }, [jwt, setAuthenticated]);

  const login = useCallback(
    ({ authenticationRequest }) => {
      apiPost("login/authenticate", authenticationRequest)
        .then((data) => {
          localStorage.setItem("token", data.jwt);
          setAuthenticated(true);
          history.push("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [setAuthenticated, history],
  );

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    setAuthenticated(false);
    history.push("/login");
  }, [setAuthenticated, history]);

  return {
    isAuthenticated,
    login,
    logout,
  };
};

export default useAuth;
