import React from "react";
import "./style.css";

const ProfitRunner = ({ profit = 0, show = true }) => {
  const className = profit === 0 ? "" : profit > 0 ? "positive" : "negative";
  const num = Math.abs(profit);
  const sign = profit < 0 ? "-" : "";
  return <span className={className}>{show && sign + "€" + num}</span>;
};

export default ProfitRunner;
