import { useState, useCallback } from "react";
import { apiPost } from "../Api_Functions";

const useServiceWorker = (
  script,
  publicVapidKey,
  subscribePostUrl,
  unSubscribePostUrl,
) => {
  const [subscription, setSubscription] = useState(undefined);

  const urlBase64ToUint8Array = useCallback((base64String) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }, []);

  const unSubscribe = useCallback(() => {
    if (subscription && unSubscribePostUrl) {
      apiPost(unSubscribePostUrl, subscription)
        .then(() => {
          setSubscription(undefined);
        })
        .catch((err) => console.log(err));
    }
  }, [subscription, unSubscribePostUrl]);

  const createNewSubscription = useCallback(() => {
    // Service Worker
    navigator.serviceWorker
      .register(script, {
        scope: "/",
      })
      .then((register) => {
        register.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
          })
          .then((newSub) => {
            if (subscribePostUrl) {
              apiPost(subscribePostUrl, newSub)
                .then(() => {
                  setSubscription(newSub);
                })
                .catch((err) => console.log(err));
            }
          });
      });
  }, [publicVapidKey, script, subscribePostUrl, urlBase64ToUint8Array]);

  const beginSubscription = useCallback(() => {
    if (subscription) {
      return;
    }

    createNewSubscription();
  }, [subscription, createNewSubscription]);

  const subscribe = useCallback(() => {
    if ("serviceWorker" in navigator) {
      beginSubscription();
    }
  }, [beginSubscription]);

  return {
    subscribe,
    unSubscribe,
  };
};

export default useServiceWorker;
