const getProfitStatus = (profits) => {
  let status = "none";
  let selectionId = 0;

  if (
    profits.length === 0 ||
    profits.every((profit) => profit.quantity === 0)
  ) {
    return [status, selectionId];
  }

  const positives = profits.filter((profit) => profit.quantity > 0);

  if (positives.length === profits.length) {
    status = "positive";
  }

  if (positives.length === 0) {
    status = "negative";
  }

  if (positives.length === 1) {
    status = "back";
    selectionId = positives[0].selectionId;
  }

  if (positives.length === profits.length - 1) {
    status = "lay";
    selectionId = profits.filter((profit) => profit.quantity <= 0)[0]
      .selectionId;
  }

  return [status, selectionId];
};

export default getProfitStatus;
