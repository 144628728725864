import React from "react";
import "./style.css";
import ProfitRunner from "../ProfitRunner";
import getRunnerProfit from "../../utils/getRunnerProfit";

const RunnerHeader = ({ marketOrder, runnerId }) => {
  const catalogueRunner = marketOrder.marketCatalogue.runners.find(
    (catRunner) => catRunner.selectionId === runnerId,
  );
  const actualProfit = getRunnerProfit(marketOrder, runnerId);
  const ifMatchedProfit = getRunnerProfit(marketOrder, runnerId, true);
  return (
    <div className="runner-header-container">
      {catalogueRunner.runnerName}
      <ProfitRunner profit={actualProfit} />
      <ProfitRunner
        profit={ifMatchedProfit}
        show={ifMatchedProfit !== actualProfit}
      />
    </div>
  );
};

export default RunnerHeader;
