import { makeStyles } from "@material-ui/core";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { AuthenticationContextProvider } from "../../context/AuthenticationContext";
import AuthenticatedRoute from "../Auth/AuthenticatedRoute";
import MarketOrders from "../MarketOrders";
import Login from "../Login/Login";
import Test from "../Test";
import Home from "./Home";
import TopBar from "./TopBar";
import "./style.css";
import MostTradedEvents from "../MostTradedEvents";

const estilos = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    margin: "0 auto",
    // padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const MainContainer = () => {
  const classes = estilos();

  return (
    <AuthenticationContextProvider>
      <div className={classes.root}>
        <TopBar />
        <div className={classes.content}>
          <div className={classes.toolbar}></div>
          <Switch>
            <AuthenticatedRoute path="/" component={Home} exact />
            <AuthenticatedRoute
              path="/mostTradedEvents"
              component={MostTradedEvents}
              exact
            />
            <AuthenticatedRoute
              path="/marketOrders"
              component={MarketOrders}
              exact
            />
            <Route path="/test" component={Test} exact />
            <Route path="/login" component={Login} exact />
          </Switch>
        </div>
      </div>
    </AuthenticationContextProvider>
  );
};

export default MainContainer;
