import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";

const HomeItem = (props) => {
  const { link, icon, text } = props;
  return (
    <ListItem button component={Link} to={link}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default HomeItem;
