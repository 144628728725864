import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  homeButton: {
    fontSize: "1.25rem",
  },
}));

const TopBar = () => {
  const history = useHistory();
  const { isAuthenticated, logout } = useAuth();

  const redirectToLogin = () => {
    history.push("/login");
  };

  const redirectToHome = () => {
    history.push("/");
  };

  const buttonPropsGetter = () => {
    if (isAuthenticated) {
      return {
        callback: logout,
        text: "Logout",
      };
    } else {
      return {
        callback: redirectToLogin,
        text: "Login",
      };
    }
  };

  const getTopBarButton = () => {
    let properties = buttonPropsGetter();
    return (
      <Button variant="text" color="inherit" onClick={properties.callback}>
        {properties.text}
      </Button>
    );
  };

  const classes = useStyles();
  return (
    <AppBar position="fixed" color="primary">
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <Button
            variant="text"
            color="inherit"
            onClick={redirectToHome}
            className={classes.homeButton}
          >
            BET MANAGER
          </Button>
        </Typography>
        {getTopBarButton()}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
