import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useServiceWorker from "../../hooks/useServiceWorker";

const PUBLIC_VAPID_KEY =
  "BAKsq0N03XNyMJUUhSmVEHIP0VxnO0rQsON4AOld-3A-KuZSUfk8obmg7vuCdXQm8ncCF87bSQoM3A7OD7RSfXo";

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { subscribe } = useServiceWorker(
    "/sw.js",
    PUBLIC_VAPID_KEY,
    "pushSubscriptions/pushSubscribe",
    "pushSubscriptions/pushUnSubscribe",
  );
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      subscribe();
    }
  }, [isAuthenticated, subscribe]);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  } else {
    subscribe();
    return (
      <div>
        <Route render={(props) => <Component {...props} />} {...rest} />
      </div>
    );
  }
};

export default AuthenticatedRoute;
