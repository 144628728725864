const getMoneyOperation = (a, b, op = "+") => {
  a = Math.round(a * 100);
  b = Math.round(b * 100);
  if (op === "-") {
    return (a - b) / 100;
  }

  if (op === "*") {
    return (a * b) / 10000;
  }

  if (op === "/") {
    return Math.round((100 * a) / b) / 100;
  }

  return (a + b) / 100;
};

export default getMoneyOperation;
