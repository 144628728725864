import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { apiPost } from "../../Api_Functions";
import getFailSuccessCssColor from "../../utils/getFailSuccessCssColor";

const useStyles = makeStyles({
  cancelButton: () => ({
    color: "white",
    background: "transparent",
    border: "none",
  }),
  sendButton: {
    color: "black",
    backgroundColor: getFailSuccessCssColor("fail"),
    "&:hover": {
      backgroundColor: getFailSuccessCssColor("fail"),
    },
  },
});

const CancelOrder = ({ marketId, betId, size }) => {
  const classes = useStyles();
  const [showAction, setShowAction] = useState(false);
  const handleShow = () => {
    setShowAction((prev) => !prev);
  };
  const handleSend = () => {
    let cancelData = {
      marketId: marketId,
      betId: betId,
      sizeReduction: size,
    };

    apiPost("cancelOrder", cancelData)
      .then((data) => console.log("ok", data))
      .catch((error) => console.log("error", error));
  };
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1f",
        placeItems: "center",
        width: "100%",
        rowGap: "1.5rem",
        border: "1px solid white",
        borderRadius: "0.5rem",
        padding: "0.75rem",
      }}
    >
      <div>
        <Button
          variant="outlined"
          onClick={handleShow}
          className={classes.cancelButton}
        >
          Cancel bet
        </Button>
      </div>
      {showAction && (
        <Button
          variant="outlined"
          onClick={handleSend}
          className={classes.sendButton}
        >
          Ok
        </Button>
      )}
    </div>
  );
};

export default CancelOrder;
