import getMoneyOperation from "./getMoneyOperation";

const getRunnerProfit = (marketOrder, runnerId, matchingOrders = false) => {
  let profitRunner = marketOrder.marketProfitAndLoss.profitAndLosses.find(
    (catRunner) => catRunner.selectionId === runnerId,
  ).ifWin;

  return getMoneyOperation(
    profitRunner,
    calcRunnerProfit(marketOrder, runnerId, matchingOrders),
  );
};

export default getRunnerProfit;

const calcRunnerProfit = (marketOrder, runnerId, matchingOrders) => {
  if (!matchingOrders) {
    return 0;
  }

  let addingOrders = getOrders(marketOrder, runnerId, "BACK", true);
  addingOrders = addingOrders.concat(
    getOrders(marketOrder, runnerId, "LAY", false),
  );

  let substractOrders = getOrders(marketOrder, runnerId, "LAY", true);
  substractOrders = substractOrders.concat(
    getOrders(marketOrder, runnerId, "BACK", false),
  );

  let sum = addingOrders.reduce(
    (acc, order) =>
      (acc = getMoneyOperation(acc, getOrderProfitOrRisk(order, true))),
    0,
  );

  let sub = substractOrders.reduce(
    (acc, order) =>
      (acc = getMoneyOperation(acc, getOrderProfitOrRisk(order, false))),
    0,
  );

  return getMoneyOperation(sum, sub, "-");
};

const getOrderProfitOrRisk = (order, isProfit = true) => {
  if (
    (isProfit && order.side === "LAY") ||
    (!isProfit && order.side === "BACK")
  ) {
    return order.sizeRemaining;
  }
  return getMoneyOperation(
    getMoneyOperation(order.price, 1, "-"),
    order.sizeRemaining,
    "*",
  );
};

const getOrders = (marketOrder, runnerId, side, runnerEqual = true) => {
  let runners = marketOrder.marketBook.runners.filter((runner) => {
    if (runnerEqual) {
      return runner.selectionId === runnerId;
    }
    return runner.selectionId !== runnerId;
  });

  let orders = [];

  runners.forEach((runner) => {
    if (runner.orders) {
      orders = orders.concat(
        runner.orders.filter(
          (order) => order.status === "EXECUTABLE" && order.side === side,
        ),
      );
    }
  });
  return orders;
};
