import React, { useState } from "react";
import { Button, List, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
/*ICONS */
import HomeItem from "./HomeItem";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { apiGet } from "../../Api_Functions";

const items = [
  {
    link: "/mostTradedEvents",
    icon: <MonetizationOnIcon />,
    text: "Most traded events",
  },
  {
    link: "/marketOrders",
    icon: <AccountBalanceIcon />,
    text: "Market management",
  },
];

const Home = () => {
  const [disableButton, setDisableButton] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const handleClick = () => {
    setDisableButton(true);
    apiGet("login/betfairRelogin")
      .then((data) => {
        let newSeverity = "error";
        let newMessage = "Relogin went wrong";
        if (data) {
          newSeverity = "success";
          newMessage = "Relogin ok";
        }
        setSeverity(newSeverity);
        setMessage(newMessage);
      })
      .finally(() => {
        setDisableButton(false);
        setOpenSnackbar(true);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <>
      <List component="nav">
        {items.map((item) => (
          <HomeItem
            key={item.text}
            link={item.link}
            icon={item.icon}
            text={item.text}
          />
        ))}
      </List>
      <div style={{ textAlign: "center", marginTop: "3rem" }}>
        <Button
          variant="contained"
          color="primary"
          disabled={disableButton}
          onClick={handleClick}
        >
          Restart betfair client
        </Button>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Home;
