import React, { useState } from "react";
import PriceSelector from "../PriceSelector";
import "./style.css";
import { Button, makeStyles } from "@material-ui/core";
import getSideCssColor from "../../utils/getSideCssColor";
import getRunnerProfit from "../../utils/getRunnerProfit";
import ProfitRunner from "../ProfitRunner";
import getRunnerName from "../../utils/getRunnerName";
import getMoneyOperation from "../../utils/getMoneyOperation";
import getPotSize from "../../utils/getPotSize";
import { apiPost } from "../../Api_Functions";

const useStyles = makeStyles({
  closeButton: (props) => ({
    color: "black",
    backgroundColor: getSideCssColor(props.nextProfitStatus, false),
    "&:hover": {
      backgroundColor: getSideCssColor(props.nextProfitStatus),
    },
  }),
});

const Results = ({
  marketOrder,
  selectedPrice,
  side,
  nextSelectionId,
  pot,
}) => {
  const size = getMoneyOperation(pot, selectedPrice, "/");
  const getNewCalc = (marketOrder, selectionId) => {
    let profit = getRunnerProfit(marketOrder, selectionId, true);
    if (nextSelectionId === selectionId) {
      if (side === "back") {
        profit = getMoneyOperation(
          profit,
          getMoneyOperation(
            getMoneyOperation(selectedPrice, 1, "-"),
            size,
            "*",
          ),
        );
      } else {
        profit = getMoneyOperation(
          profit,
          getMoneyOperation(
            getMoneyOperation(selectedPrice, 1, "-"),
            size,
            "*",
          ),
          "-",
        );
      }
    } else {
      if (side === "lay") {
        profit = getMoneyOperation(profit, size);
      } else {
        profit = getMoneyOperation(profit, size, "-");
      }
    }
    return profit;
  };
  return (
    <div>
      {marketOrder.marketBook.runners.map((runner) => (
        <div key={runner.selectionId}>
          <div>
            {getRunnerName(marketOrder.marketCatalogue, runner.selectionId)}
          </div>
          <div>
            <ProfitRunner
              profit={getRunnerProfit(marketOrder, runner.selectionId, true)}
            />
            {" >> "}
            <ProfitRunner
              profit={getNewCalc(marketOrder, runner.selectionId)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
const ClosePosition = ({
  toggleButtonText = "Close Position",
  nextProfitStatus,
  nextSelectionId,
  marketOrder,
  allOrders = true,
  currentOrder,
}) => {
  const classes = useStyles({ nextProfitStatus });
  const getInitialPrice = () => {
    let runner = marketOrder.marketBook.runners.find(
      (runner) => runner.selectionId === nextSelectionId,
    );
    if (runner === undefined) {
      return 2;
    }
    let runnerPrices = runner.ex;
    let value = 2;
    let available;
    if (nextProfitStatus === "back") {
      available = runnerPrices.availableToLay;
    } else {
      available = runnerPrices.availableToBack;
    }

    if (available && available.length > 0) {
      value = available[0].price;
    }
    return value;
  };
  const pot = getPotSize(marketOrder, allOrders);
  const [showAction, setShowAction] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(() => getInitialPrice());
  const handleShow = () => {
    setShowAction((prev) => !prev);
  };
  const handlePriceChange = (value) => {
    setSelectedPrice(value);
  };

  const handleSend = () => {
    const placeOrderData = {
      marketId: marketOrder.marketCatalogue.marketId,
      selectionId: nextSelectionId,
      side: nextProfitStatus.toString().toUpperCase(),
      price: selectedPrice,
      size: getMoneyOperation(pot, selectedPrice, "/"),
    };

    if (currentOrder) {
      if (currentOrder.price === selectedPrice) {
        placeOrderData.size = getMoneyOperation(
          placeOrderData.size,
          currentOrder.size,
          "-",
        );
      } else {
        let cancelData = {
          marketId: marketOrder.marketBook.marketId,
          betId: currentOrder.betId,
          sizeReduction: currentOrder.size,
        };

        apiPost("cancelOrder", cancelData)
          .then((data) => console.log("ok", data))
          .catch((error) => console.log("error", error));
      }
    }

    apiPost("placeOrders", placeOrderData)
      .then((data) => console.log("ok", data))
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div
        style={{
          display: "grid",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleShow}
          className={classes.closeButton}
        >
          {toggleButtonText}
        </Button>
      </div>
      {showAction && nextSelectionId !== 0 && (
        <div className="close-position-container">
          <div className="close-position-container-selector">
            <div
              style={{
                display: "grid",
                placeItems: "center",
                rowGap: "0.5rem",
              }}
            >
              {getRunnerName(marketOrder.marketCatalogue, nextSelectionId)}
              <PriceSelector
                price={() => getInitialPrice()}
                side={nextProfitStatus}
                onChange={(value) => handlePriceChange(value)}
              />
            </div>
            <Results
              marketOrder={marketOrder}
              selectedPrice={selectedPrice}
              side={nextProfitStatus}
              nextSelectionId={nextSelectionId}
              pot={pot}
            />
          </div>
          <Button
            variant="outlined"
            onClick={handleSend}
            className={classes.closeButton}
          >
            Send
          </Button>
        </div>
      )}
    </>
  );
};

export default ClosePosition;
