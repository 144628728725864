import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { sha512 } from "js-sha512";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { apiGet } from "../../Api_Functions";
import useAuth from "../../hooks/useAuth";

function Copyright() {
  return (
    <Typography variant="body2" color="textPrimary" align="center">
      {"Copyright © Borja Ochoa "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  centerGrid: {
    display: "grid",
  },
}));

const Login = () => {
  const classes = useStyles();

  const [challenge, setChallenge] = useState({});
  const [userValue, setUserValue] = useState("");
  const [passValue, setPassValue] = useState("");

  const { isAuthenticated, login } = useAuth();

  const getChallenge = (signal) => {
    apiGet("login/getChallenge", { signal: signal })
      .then((data) => {
        setChallenge(data);
      })
      .catch((error) => {});
  };

  const handleOnLogIn = (event) => {
    event.stopPropagation();
    let pass = encrypt();
    let authenticationRequest = {
      username: userValue,
      password: pass,
      challengeId: challenge.id,
    };
    login({ authenticationRequest });
  };

  const encrypt = () => {
    let res = sha512(passValue);

    res = userValue + res;
    res = sha512(res);

    res += challenge.challenge;
    res = sha512(res);
    return res;
  };

  const handleOnChangeUser = (event) => {
    setUserValue(event.target.value);
  };

  const handleOnChangePass = (event) => {
    setPassValue(event.target.value);
  };

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      // Do code here
      handleOnLogIn(event);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    if (!challenge.challenge) {
      getChallenge(signal);
    }
    return () => {
      abortController.abort();
    };
  }, [challenge]);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {!challenge.challenge ? (
          <div className={classes.centerGrid}>
            <CircularProgress />
          </div>
        ) : (
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Log in
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="user"
                label="User"
                name="user"
                autoComplete="user"
                autoFocus
                value={userValue}
                onChange={handleOnChangeUser}
                onKeyPress={(event) => handleOnKeyPress(event)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={passValue}
                onChange={handleOnChangePass}
                onKeyPress={(event) => handleOnKeyPress(event)}
              />
              {/* <FormControlLabel
                                                        control={<Checkbox value="remember" color="primary" />}
                                                        label="Remember me"
                                                    /> */}
              <Button
                //type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(event) => handleOnLogIn(event)}
              >
                Log In
              </Button>
              {/* <Grid container>
                                                        <Grid item xs>
                                                            <Link href="#" variant="body2">
                                                                Forgot password?
                                                            </Link>
                                                        </Grid>
                                                        <Grid item>
                                                            <Link href="#" variant="body2">
                                                                {"Don't have an account? Sign Up"}
                                                            </Link>
                                                        </Grid>
                                                    </Grid> */}
            </form>
            <Box mt={8}>
              <Copyright />
            </Box>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Login;
