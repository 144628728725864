import React from "react";
import useIntervalApiGet from "../../hooks/useIntervalApiGet";
import TradedEventInfo from "./TradedEventInfo";

const MostTradedEvents = () => {
  const [events] = useIntervalApiGet("getMaxTradedMarketsData");

  return (
    <>
      {events &&
        events.map((event, index) => (
          <TradedEventInfo key={"event_info_" + index} event={event} />
        ))}
    </>
  );
};

export default MostTradedEvents;
