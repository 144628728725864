import { useState, useEffect } from "react";
import { apiGet } from "../Api_Functions";

const useIntervalApiGet = (fetchUrl, intervalTime = 1000) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let fetchedData = await apiGet(fetchUrl);
      setData(fetchedData);
    };
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, intervalTime);
    return () => {
      clearInterval(interval);
    };
  }, [fetchUrl, intervalTime]);

  return [data];
};

export default useIntervalApiGet;
