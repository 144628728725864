import React from "react";
import "./style.css";
import getSideClass from "../../utils/getSideClass";

export default function PriceSizeDiv(props) {
  const { price = "", size = "", side = "back", primary = true } = props;

  return (
    <div className={"bet-div " + getSideClass(side, primary)}>
      <span className="s-price">{price}</span>
      <span className="s-size">{size !== "" ? "€" + size : size}</span>
    </div>
  );
}
