const pips = {
  2: 1,
  3: 2,
  4: 5,
  6: 10,
  10: 20,
  20: 50,
  30: 100,
  50: 200,
  100: 500,
  1000: 1000,
};

export default pips;
