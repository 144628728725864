import pips from "./getPips";

export default function (value) {
  if (value >= 1000) {
    return 1000;
  }

  if (value <= 1.01) {
    return 1.01;
  }

  const [key, prevKey] = getKey(value);

  let a = prevKey * 100;

  while (a < Math.round(value * 100) && a + pips[key] <= key * 100) {
    a += pips[key];
  }

  return a / 100;
}

const getKey = (value) => {
  let prevKey = 1.01;
  for (var key in pips) {
    if (
      Math.round(value * 100) / 100 >= prevKey &&
      Math.round(value * 100) / 100 < key
    ) {
      return [key, prevKey];
    }

    prevKey = key;
  }

  return [2, 1.01];
};
