import { makeStyles } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { CheckCircle, ExpandMore, WatchLater } from "@material-ui/icons";
import React from "react";
import getFailSuccessCssColor from "../../utils/getFailSuccessCssColor";
import getSideClass from "../../utils/getSideClass";
import getSideCssColor from "../../utils/getSideCssColor";
import CircularProgress from "../BetProgress";
import CancelOrder from "../CancelOrder";
import ClosePosition from "../ClosePosition";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "1.15rem",
    flexBasis: "33.33%",
    flexShrink: 0,
    fontWeight: "400",
  },
  heading2: {
    fontSize: "1.15rem",
    flexBasis: "33.33%",
    flexShrink: 0,
    fontWeight: "600",
  },
  secondaryHeading: {
    fontSize: "1rem",
    //color: theme.palette.text.secondary,
    margin: "0 0 0 auto",
  },
  accordion: {
    background: "#303030",
    color: "#000",
  },
  accordionBack: {
    background: getSideCssColor("back"),
    borderRadius: "inherit",
  },
  accordionLay: {
    background: getSideCssColor("lay"),
    borderRadius: "inherit",
  },
  done: {
    background: "green",
  },
}));

const getIsMatchedIcon = (size, matched) => {
  if (size === matched) {
    return <CheckCircle style={{ color: getFailSuccessCssColor() }} />;
  }
  return <WatchLater style={{ color: getFailSuccessCssColor("fail") }} />;
};

const getOpposite = (status) => {
  return status.toString().toLowerCase() === "lay" ? "back" : "lay";
};

const MarketCurrentOrder = ({
  side,
  size,
  price,
  matched,
  marketOrder,
  betId,
  selectionId,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          className={
            getSideClass(side) === "lay"
              ? classes.accordionLay
              : classes.accordionBack
          }
          expandIcon={<ExpandMore style={{ color: "#000" }} />}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "3fr 1fr 1fr",
              width: "100%",
            }}
          >
            <div>
              <Typography className={classes.heading}>{size}€</Typography>
            </div>
            <div>
              <Typography className={classes.heading2}>{price}</Typography>
            </div>
            <div style={{ textAlign: "right" }}>
              <Typography className={classes.secondaryHeading}>
                {getIsMatchedIcon(size, matched)}
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              placeItems: "center",
              width: "100%",
              marginTop: "0.5rem",
              rowGap: "0.5rem",
            }}
          >
            <CircularProgress
              side={getSideClass(side)}
              size={size}
              matched={matched}
              radius={4}
            />
            {matched > 0 && (
              <ClosePosition
                toggleButtonText="Close position"
                marketOrder={marketOrder}
                nextProfitStatus={getOpposite(side)}
                nextSelectionId={selectionId}
                allOrders={false}
                currentOrder={{
                  price: price,
                  size: size,
                  betId: betId,
                }}
              />
            )}
            {matched < size && (
              <CancelOrder
                marketId={marketOrder.marketBook.marketId}
                betId={betId}
                size={size}
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MarketCurrentOrder;
