import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { apiGet } from "../Api_Functions";

const useStyles = makeStyles((theme) => ({
  backbutton: {
    backgroundColor: "#a6d8ff",
  },
  laybutton: {
    backgroundColor: "#fac9d4",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Test = () => {
  const classes = useStyles();

  const [marketData, setMarketData] = useState({});

  useEffect(() => {
    const fetchMarketData = async () => {
      let data = await apiGet("test/pruebaKOEvent");
      setMarketData(data);
    };
    let interval = setInterval(fetchMarketData, 1000);
    //destroy interval on unmount
    return () => clearInterval(interval);
  });

  const printDate = (milliseconds) => {
    let dateObject = new Date(milliseconds);
    const options = {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return dateObject.toLocaleString("es-ES", options);
  };

  return (
    <div>
      {marketData.exchangeEvent && marketData.exchangeEvent.marketCatalogue ? (
        <div>
          <h3>
            {marketData.exchangeEvent.marketCatalogue.event.name} -{" "}
            {marketData.exchangeEvent.marketCatalogue.competition.name}
          </h3>
          <p>
            {printDate(marketData.exchangeEvent.marketCatalogue.event.openDate)}
          </p>
          <p>
            {marketData.exchangeEvent.marketCatalogue.description.marketType}:{" "}
            {marketData.exchangeEvent.marketCatalogue.totalMatched}€
          </p>
          {marketData.exchangeEvent.marketBookList &&
            marketData.exchangeEvent.marketBookList.length > 0 &&
            marketData.exchangeEvent.marketBookList[0].runners &&
            marketData.exchangeEvent.marketBookList[0].runners.length > 0 && (
              <TableContainer component={Paper}>
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right">SPORT</TableCell>
                      <TableCell align="center">BACK</TableCell>
                      <TableCell align="left">LAY</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {marketData.exchangeEvent.marketBookList[0].runners.map(
                      (runner) => (
                        <TableRow key={runner.selectionId}>
                          <TableCell component="th" scope="row">
                            {
                              marketData.exchangeEvent.runnerCatalogsMap[
                                runner.selectionId
                              ].runnerName
                            }
                          </TableCell>
                          <TableCell align="right">
                            <Button variant="contained" color="primary">
                              {
                                marketData.sportsbookEvent.runnersPrices[
                                  runner.selectionId
                                ]
                              }
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              className={classes.backbutton}
                            >
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <Typography variant="h5" gutterBottom>
                                    {runner.ex.availableToBack[0].price}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="caption" gutterBottom>
                                    €{runner.ex.availableToBack[0].size}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Button>
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              variant="contained"
                              className={classes.laybutton}
                            >
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <Typography variant="h5" gutterBottom>
                                    {runner.ex.availableToLay[0].price}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="caption" gutterBottom>
                                    €{runner.ex.availableToLay[0].size}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Button>
                          </TableCell>
                          {/* <TableCell align="left">{runner.ex.availableToBack[0].size}€ @ {runner.ex.availableToBack[0].price}</TableCell>
                                                <TableCell align="right">{runner.ex.availableToLay[0].size}€ @ {runner.ex.availableToLay[0].price}</TableCell> */}
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
        </div>
      ) : (
        <div className={classes.paper}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Test;
